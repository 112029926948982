import { Component, Input } from '@angular/core';
import { DepositsByMonth } from 'ds-api';

@Component({
  selector: 'co-deposits-monthly',
  templateUrl: './deposits-monthly.component.html',
  styleUrls: ['./deposits-monthly.component.scss']
})
export class DepositsMonthlyComponent {
  @Input() depositsByMonthMap: DepositsByMonth[];
}
