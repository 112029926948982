<co-accordion
  *ngFor="
    let depositByMonth of depositsByMonthMap;
    let first = first;
    let last = last
  "
  [ngClass]="{ first: first, last: last }"
  class="stacked accordion--mobile-header-space-xxl first accordion--no-padding"
  [title]="depositByMonth.monthIndex | dateMonthStrFormat"
  [titleOpen]="depositByMonth.monthIndex | dateMonthStrFormat"
  [titleRight]="depositByMonth.depositsSum | currencyFormat"
  [titleRightOpen]="depositByMonth.depositsSum | currencyFormat"
>
  <table
    aria-label="{{ depositByMonth.monthIndex | content }}"
    class="pfa-table table-responsive align-right--column-4 thead-align--center"
  >
    <thead>
      <tr>
        <th [coContent]="'DL.IN01.C41'"></th>
        <th [coContent]="'DL.IN01.C42'"></th>
        <th [coContent]="'DL.IN01.C127'"></th>
        <th [coContent]="'DL.IN01.C43'"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let deposit of depositByMonth.deposits">
        <td
          [attr.data-label]="'DL.IN01.C41' | content"
          class="deposits-overview-table"
        >
          <div [coContent]="deposit.indbetalingsDato"></div>
        </td>
        <td
          [attr.data-label]="'DL.IN01.C42' | content"
          class="deposits-overview-table"
        >
          <div
            *ngIf="!deposit.periodeTekstContentId && !deposit.gippLinkContentId"
          >
            {{ deposit.fraDato }} - {{ deposit.tilDato }}
          </div>
          <div
            *ngIf="deposit.periodeTekstContentId && !deposit.gippLinkContentId"
            coContent="{{ deposit.periodeTekstContentId }}"
          ></div>
          <a
            *ngIf="deposit.gippLinkContentId"
            class="link"
            [routerLink]="'/minedokumenter'"
          >
            <p coContent="{{ deposit.gippLinkContentId }}"></p>
          </a>
          <div
            *ngIf="deposit.gippContentId"
            coContent="{{ deposit.gippContentId }}"
            coContentSubst="{{ deposit.gippKrPolicenummer }}"
          ></div>
          <div
            *ngIf="deposit.indbetalerTekstContentId"
            coContent="{{ deposit.indbetalerTekstContentId }}"
            class="notetext"
          ></div>
        </td>
        <td
          [attr.data-label]="'DL.IN01.C127' | content"
          class="deposits-overview-table"
        >
          {{ deposit.firmaNavn }}
        </td>
        <td
          [attr.data-label]="'DL.IN01.C43' | content"
          class="deposits-overview-table"
        >
          <div class="details-value">
            {{ deposit.beloeb | currencyFormat }}
          </div>
          <div
            [coContent]="deposit.indregnetDatoContentId"
            [coContentSubst]="deposit.indregnetDato"
            class="notetext"
          ></div>
        </td>
      </tr>
    </tbody>
  </table>
</co-accordion>
